export enum BucketingType {
  Ungrouped = 'UNGROUPED',
  StudyType = 'STUDY_TYPE',
}

export enum SurveyStatus {
  Pending = 'PENDING',
  Processing = 'PROCESSING',
  Failed = 'FAILED',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Hold = 'HOLD',
  Unhold = 'UNHOLD',
}

export enum DrawerStatus {
  Glossary = 'glossary',
  Filter = 'filter',
}

export enum ViewStatus {
  Calendar = 'calendar',
  Table = 'table',
}

export enum IncentiveSurveyActions {
  FilterIncentiveSurvey = 'filterIncentiveSurvey',
  FilterIncentiveSurveyLog = 'filterIncentiveSurveyLog',
  ErrorMessage = 'errorMessage',
  SurveyStatusChanged = 'surveyStatusChanged',
}

export enum SimSurveyActions {
  QueryVariables = 'query-variables',
  SurveyData = 'survey-data',
  SurveyFilter = 'survey-filter',
}

export enum SprintLaneStatus {
  InputQueue = 'Input Queue',
  PreProgramming = 'Pre-Programming',
  Programming = 'Programming',
  Fielding = 'Fielding',
  PostFielding = 'Post-Fielding',
  Closed = 'Closed',
  Done = 'Done',
}

export enum SimStatus {
  Open = 'Open',
  Resolved = 'Resolved',
}
