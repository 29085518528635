import { addYears, startOfQuarter, subQuarters } from 'date-fns';
import React, { createContext, useMemo, useReducer } from 'react';

import { IncentiveSurveyActions as Action } from '../constants/enum';
import { IncentiveSurveyAction, IncentiveSurveyContextProps, IncentiveSurveyState } from '../types/IncentiveSurveyContext';

type ContextProps = { children: React.ReactNode };

const currentDate = new Date();
const previousQuarter = subQuarters(currentDate, 1);
const nextThreeYearsQuarter = addYears(currentDate, 3);

const defaultStartDate = startOfQuarter(previousQuarter);
const defaultEndDate = startOfQuarter(nextThreeYearsQuarter);

const defaultFilterSurvey = {
  startDate: defaultStartDate,
  endDate: defaultEndDate,
};

const defaultFilterSurveyLog = {
  startDate: defaultStartDate,
  endDate: defaultEndDate,
};

const defaultIncentiveSurveyState: IncentiveSurveyState = {
  filterSurvey: defaultFilterSurvey,
  filterSurveyLog: defaultFilterSurveyLog,
  surveyStatusChanged: null,
};

export const IncentiveSurveyContext = createContext<IncentiveSurveyContextProps>({
  ...defaultIncentiveSurveyState,
  dispatch: () => {},
});

const incentiveSurveyReducer = (state: IncentiveSurveyState, action: IncentiveSurveyAction): IncentiveSurveyState => {
  switch (action.type) {
    case Action.FilterIncentiveSurvey: {
      return { ...state, filterSurvey: action.payload };
    }
    case Action.FilterIncentiveSurveyLog: {
      return { ...state, filterSurveyLog: action.payload };
    }
    case Action.SurveyStatusChanged: {
      return { ...state, surveyStatusChanged: action.payload };
    }
    default: {
      return defaultIncentiveSurveyState;
    }
  }
};

export const IncentiveSurveyContextProvider = ({ children }: ContextProps) => {
  const [state, dispatch] = useReducer(incentiveSurveyReducer, defaultIncentiveSurveyState);
  const context = useMemo(() => ({ ...state, dispatch }), [state, dispatch]);

  return <IncentiveSurveyContext.Provider value={context}>{children}</IncentiveSurveyContext.Provider>;
};
